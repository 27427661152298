<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcScriptureDistribution }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load($event)"
        :tooltip="translations.tcTooltip"
        :i18n="translations.components"
      />
      <!-- / Camp Selector -->
      <div class="page-body container mx-med">
        <section class="section-1">
          <h4>
            {{ translations.tcRecentScripturePlacementDates }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcTooltip">
              <i-tooltip />
            </span>
          </h4>
          <div class="row">
            <div class="col col-6">
              <div v-if="this.recent_placements.length == 0">
                {{ noRecentPlacementsMessage }}
              </div>
              <div v-else>
                <DateCard
                  v-for="(recent_placement, index) in this.preppedRecentPlacements"
                  :i18n="translations.common"
                  :key="index"
                  :obj="recent_placement.date"
                >
                  <BasicDateCard
                    v-if="translations.common"
                    :i18n="translations.components"
                    :obj="{
                      title: recent_placement.title,
                      description: recent_placement.description,
                      placement_key: recent_placement.placement_key,
                      schedule: recent_placement.schedule,
                      mtg_key: recent_placement.mtg_key,
                      loc_key: recent_placement.loc_key,
                      destination: recent_placement.destination
                    }"
                  />
                </DateCard>
              </div>
              <button @click="handleRecentPlacementDate" class="btn btn-primary btn-w-med mb-3 mr-1">
                {{ translations.tcViewMore }}
              </button>
              <button
                v-if="iCanSee(secured_controls.add_scripture_placement_facility_dates_button)"
                @click="handleAddPlacement"
                class="btn btn-primary btn-w-med mb-3"
              >
                {{ translations.tcAddScripturePlacement }}
              </button>
            </div>
            <div class="col col-6">
              <NumberCard
                v-for="(stat, index) in topWidgetData"
                :key="index"
                :obj="stat"
                :i18n="translations.components"
              />
            </div>
          </div>
        </section>
        <section class="section-2">
          <h4>{{ translations.tcUpcomingScripturePlacementDates }}</h4>
          <div class="row">
            <div class="col col-6">
              <div v-if="this.upcoming_placements.length == 0">
                {{ noUpcomingPlacementsMessage }}
              </div>
              <div v-else>
                <DateCard
                  v-for="(upcoming_placement, index) in this.preppedUpcomingPlacements"
                  :i18n="translations.common"
                  :key="index"
                  :obj="upcoming_placement.date"
                >
                  <BasicDateCard
                    v-if="translations.common"
                    :obj="{
                      title: upcoming_placement.title,
                      description: upcoming_placement.description,
                      placement_key: upcoming_placement.placement_key,
                      schedule: upcoming_placement.schedule,
                      mtg_key: upcoming_placement.mtg_key,
                      loc_key: upcoming_placement.loc_key,
                      destination: upcoming_placement.destination
                    }"
                    :i18n="translations.components"
                  />
                </DateCard>
              </div>
              <router-link to="/programs/sd/placement-dates" class="btn btn-primary btn-w-med mb-3">
                <div>{{ translations.tcViewMore }}</div>
              </router-link>
            </div>
            <div class="col col-6">
              <NumberCard
                v-for="(stat, index) in bottomWidgetData"
                :key="index"
                :obj="stat"
                :i18n="translations.components"
              />
            </div>
          </div>
        </section>
        <section class="section-3">
          <div class="row">
            <InfoCard
              v-for="(info, index) in scriptureDistributionInfo"
              :key="index"
              :obj="info"
              @set_program="handleSetProgram($event)"
            />
          </div>
        </section>
      </div>
      <!-- / Page Body -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import CampSelect from '@/components/CampSelect.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'scripture-distribution',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      noRecentPlacementsMessage: 'No recent placements were found.',
      noUpcomingPlacementsMessage: 'No upcoming placements were found.',
      secured_controls: {
        add_scripture_placement_facility_dates_button: '13588ae8-9c7b-4b8e-938a-e7eaa7cd7c7f'
      },
      scriptureDistributionInfo: [
        {
          class: 'col col-6',
          title: 'Placement Location List',
          content: 'View a list of all Scripture placement locations in your camp.',
          link: '/programs/sd/placement-locations',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'Placment Location List',
            width: 64,
            height: 64
          }
        },
        {
          class: 'col col-6',
          title: 'Scripture Distribution Reports',
          content: 'Run on-demand reports for your Scripture placement locations.',
          link: '/reports/report-center/sd',
          label: 'View More',
          img: {
            src: 'svgs/church-reports.svg',
            alt: 'Scripture Distribution Reports',
            width: 64,
            height: 64
          }
        },
        {
          class: 'col col-6',
          title: 'Program Resources',
          content: 'View manuals, videos, and other resources for the Scripture Distribution Program.',
          emitlink: '/resources/media-portal/program',
          itemId: '70D13440-3414-4A39-A51B-211AC831FC9B', // The sitecore ItemId for Scripture Distribution Program
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'Program Resources',
            width: 64,
            height: 64
          }
        }
      ],
      recentPlacementLink: {
        name: 'placement-dates',
        query: {},
        hash: '#pastdates'
      }
    }
  },
  components: {
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    iTooltip: iTooltip,
    NumberCard: NumberCard,
    securityBanner: SecurityBanner
  },
  methods: {
    ...mapActions({
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      clearPlacementDateDetails: 'scriptureDistribution/clearPlacementDateDetails',
      loadWidgetData: 'scriptureDistribution/loadWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey'
    }),
    async handleAddPlacement() {
      await Promise.all([
        await this.setSelectedLocationKey(''),
        await this.clearLocationDetails(),
        await this.setSelectedPlacementDateKey(''),
        await this.clearPlacementDateDetails('')
      ]).then(() => {
        this.$router.push('/programs/sd/add-placement-date')
      })
    },
    async handleRecentPlacementDate() {
      this.$router.push('/programs/sd/placement-dates#pastdates')
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.emitlink)
    },
    async locationStorePlacementKeyAndRedirect(data) {
      await this.setSelectedLocationKey(data.item.plf_org_key)
      await this.setSelectedPlacementDateKey(data.item.mtg_key)
      this.$router.push({ name: 'placement-details' })
    },
    async page_load(evt) {
      if (!evt) {
        // execute only if evt is undefined
        try {
          await Promise.all([this.setLoadingStatus(true), await this.loadWidgetData()]).then(() => true)
        } catch (e) {
          console.error('Error loading view, ', e)
        } finally {
          await this.setLoadingStatus(false)
        }
      }
    },
    async updateScriptureDistributionText() {
      if (!this.translations.components) return false

      const {
        tcNoRecentPlacementsMessage,
        tcNoUpcomingPlacementsMessage,
        tcPlacementLocationList,
        tcPlacementLocationListDesc,
        tcProgramResources,
        tcProgramResourcesDesc,
        tcScriptureDistributionReports,
        tcScriptureDistributionReportsDesc,
        tcViewMore
      } = this.translations

      this.noRecentPlacementsMessage = tcNoRecentPlacementsMessage
      this.noUpcomingPlacementsMessage = tcNoUpcomingPlacementsMessage

      this.scriptureDistributionInfo[0].title = tcPlacementLocationList
      this.scriptureDistributionInfo[0].content = tcPlacementLocationListDesc
      this.scriptureDistributionInfo[0].label = tcViewMore
      this.scriptureDistributionInfo[0].img.alt = tcPlacementLocationList

      this.scriptureDistributionInfo[1].title = tcScriptureDistributionReports
      this.scriptureDistributionInfo[1].content = tcScriptureDistributionReportsDesc
      this.scriptureDistributionInfo[1].label = tcViewMore
      this.scriptureDistributionInfo[1].img.alt = tcScriptureDistributionReports

      this.scriptureDistributionInfo[2].title = tcProgramResources
      this.scriptureDistributionInfo[2].content = tcProgramResourcesDesc
      this.scriptureDistributionInfo[2].label = tcViewMore
      this.scriptureDistributionInfo[2].img.alt = tcProgramResources

      return true
    }
  },
  computed: {
    ...mapGetters({
      bottomWidgetData: 'scriptureDistribution/bottomWidgetData',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      recent_placements: 'scriptureDistribution/recent_placements',
      topWidgetData: 'scriptureDistribution/topWidgetData',
      upcoming_placements: 'scriptureDistribution/upcoming_placements'
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    preppedRecentPlacements() {
      let placements = []
      if (!this.recent_placements) {
        return [
          {
            date: { month: '', day: '', year: '' },
            title: 'loading',
            description: '',
            schedule: ''
          }
        ]
      } else {
        placements = this.recent_placements.map((plac, idx) => {
          return {
            date: {
              month: plac.month,
              day: plac.day,
              year: plac.year
            },
            title: plac.facility_name,
            description: plac.institution_type,
            placement_key: plac.hasOwnProperty('facility_type_key') ? plac['facility_type_key'] : '',
            schedule: '',
            mtg_key: plac.mtg_key,
            loc_key: plac.plf_org_key,
            destination: plac.is_scripture_event ? '/camp-calendar/event-detail' : '/programs/sd/placement-details'
          }
        })
      }
      return placements
    },
    preppedUpcomingPlacements() {
      let placements = []
      if (!this.upcoming_placements) {
        return [
          {
            date: { month: '', day: '', year: '' },
            title: 'loading',
            description: '',
            schedule: ''
          }
        ]
      } else {
        placements = this.upcoming_placements.map((plac, idx) => {
          return {
            date: {
              month: plac.month,
              day: plac.day,
              year: plac.year
            },
            title: plac.facility_name,
            description: plac.coordinator_name,
            placement_key: plac.hasOwnProperty('facility_type_key') ? plac['facility_type_key'] : '',
            schedule: plac.start_time ? `${this.translations.tcStarts} ${plac.start_time}` : '',
            mtg_key: plac.mtg_key,
            loc_key: plac.plf_org_key,
            destination: plac.is_scripture_event ? '/camp-calendar/event-detail' : '/programs/sd/placement-details'
          }
        })
      }
      return placements
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card', 'security-banner'),
        this.getComponentTranslations('common.days', 'common.months-abbreviations', 'common.placement-facility-types')
      ])
        .then(results => {
          this.stripReadableText(results[2])
          const translatedText = {
            ...results[1],
            common: { ...this.translations.common }
          }
          this.$set(this.translations, 'components', translatedText)
          this.updateScriptureDistributionText()
          this.page_load()
        })
        .then(() => true)
    } catch (e) {
      console.error(e)
    } finally {
      await this.setLoadingStatus(false)
    }
  }
}
</script>

<style lang="scss">
/* eslint-disable */
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

h4 {
  display: flex;
  align-items: flex-start;
  .i-tooltip {
    position: relative;
    top: -5px;
    margin-left: 10px;
    line-height: 0;
  }
}

.church-ministry {
  .page-body {
    margin-top: 68px;
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 36px;
    }
  }
  .section-0 {
    margin-bottom: 60px;
    .quote-verse p {
      font-family: $lora;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 1px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 80px;
    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }
    > .row {
      margin-bottom: 28px;
    }
    .col {
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
      }
      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
    .number-card .content .w-subtitle p:not(.subtitle) {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }
  .section-1 {
    .d-flex.button-bar {
      align-items: center;
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }
    .number-card .wo-subtitle p {
      font-size: 26px;
      line-height: 32px;
    }
    .sub-text {
      font-family: $DIN;
      text-transform: uppercase;
      font-size: 20px;
      @include breakpoint(sm) {
        display: block;
        margin-left: 0 !important;
        margin-top: 30px;
      }
    }
  }
  .section-2 {
    .sub-support {
      .num {
        display: flex;
        align-items: center;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    .number-card {
      align-items: center;
    }
    .row {
      justify-content: center;
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
